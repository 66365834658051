<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Personal Information') }}
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="`${ $t('Birth date') }`"
            label-for="birth-date"
          >
            <flat-pickr
              v-model="userDataInfo.dob"
              class="form-control"
              :config="{ dateFormat: 'Y-m-d'}"
              placeholder="YYYY-MM-DD"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Mobile -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="`${ $t('Mobile') }`"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="userDataInfo.mobile"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Website -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Website"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="userDataInfo.website"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Language -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="`${ $t('Language') }`"
            label-for="language"
          >
            <v-select
              v-model="userDataInfo.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions"
              :clearable="false"
              input-id="language"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="`${ $t('Gender') }`"
            label-for="gender"
            label-class="mb-1"
          >
            <div class="d-flex">
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="A"
              >
                {{ $t('Male') }}
              </b-form-radio>
              <b-form-radio
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="B"
                class="ml-2"
              >
                {{ $t('Female') }}
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>

        <!-- Field: Contact Options -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="`${ $t('Contact Options') }`"
            label-for="contact-options"
            label-class="mb-1"
          >
            <b-form-checkbox-group
              id="contact-options"
              v-model="userDataInfo.contactOptions"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex mt-2 mb-1">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          {{ $t('Address') }}
        </h4>
      </div>

      <b-row>
        <b-col cols="6">
          <b-form-group
            :label="$t('Country')"
            label-cols-md="12"
          >
            <v-select
              v-model="model.country"
              label="name"
              :reduce="x => x.id"
              :options="statusOptions"
              :placeholder="$t('Country')"
            />
          </b-form-group>
          <b-form-group
            :label="$t('District')"
            label-cols-md="12"
          >
            <v-select
              v-model="model.district"
              label="name"
              :reduce="x => x.id"
              :options="statusOptions"
              :placeholder="$t('District')"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :label="$t('Province/City')"
            label-cols-md="12"
          >
            <v-select
              v-model="model.province"
              label="name"
              :reduce="x => x.id"
              :options="statusOptions"
              :placeholder="$t('Province/City')"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Wards')"
            label-cols-md="12"
          >
            <v-select
              v-model="model.wards"
              label="name"
              :reduce="x => x.id"
              :options="statusOptions"
              :placeholder="$t('Wards')"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group
        :label="`*` + $t('Address') + `:`"
        label-cols-md="12"
      >
        <validation-provider
          #default="{ errors }"
          name="Address"
          rules="required"
        >
          <b-form-input
            v-model="model.fullname"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('Address')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('Save changes') }}
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            {{ $t('Reset') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckboxGroup, BButton, BFormRadio,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormCheckboxGroup,
    BButton,
    BFormRadio,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      selected: '',
      statusOptions: [
        { name: 'Active', id: 'active' },
        { name: 'Blocked', id: 'blocked' },
        { name: 'Deactivated', id: 'deactivated' },
      ],
      model: {
        fullname: null,
        email: null,
        birthday: null,
        gender: 0,
        is_active: true,
        phone: null,
        address: null,
        status_local: null,
        country: null,
        district: null,
        province: null,
        wards: null,
        company: null,
        taxcode: null,
        idnumb: null,
        note: null,
      },
    }
  },
  setup() {
    const userDataInfo = ref({
      dob: null,
      mobile: '+6595895857',
      website: 'https://rowboat.com/insititious/Angelo',
      language: 'French',
      gender: 'female',
      contactOptions: ['Email', 'Message'],
      addressLine1: 'A-65, Belvedere Streets',
      addressLine2: '',
      postcode: '',
      city: 'New York',
      state: '',
      country: '',
    })

    const languageOptions = [
      'English',
      'Spanish',
      'French',
      'Russian',
      'German',
      'Arabic',
      'Sanskrit',
    ]

    const genderOptions = [
      { text: 'Male', value: 'male' },
      { text: 'Female', value: 'female' },
    ]

    const contactOptionsOptions = ['Email', 'Message', 'Phone']

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
